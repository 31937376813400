import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen1 } from '../../../components/images/support/certifications/Screen1.generated';
import { Screen2 } from '../../../components/images/support/certifications/Screen2.generated';
import { Screen3 } from '../../../components/images/support/certifications/Screen3.generated';
import { Screen4 } from '../../../components/images/support/certifications/Screen4.generated';
import { Screen5 } from '../../../components/images/support/certifications/Screen5.generated';
import { Screen6 } from '../../../components/images/support/certifications/Screen6.generated';
import { Screen7 } from '../../../components/images/support/certifications/Screen7.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "adding-certifications-"
    }}>{`Adding Certifications 🗂`}</h1>
    <p>{`In this example we will add a certification for our business`}</p>
    <h3 {...{
      "id": "lets-add-a-certification"
    }}>{`Let's add a Certification`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Certifications menu.`}</li>
    </ol>

    <Screen1 mdxType="Screen1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the "Create" button in the top right corner.`}</li>
    </ol>

    <Screen2 mdxType="Screen2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Canyou allows you to configure any type of licence or certification that a business requires. From this screen there are 2 options:`}</li>
    </ol>
    <p>{`The first option is selecting from the pre-defined certifications. For example "Driver Licence".`}</p>

    <Screen3 mdxType="Screen3" />
    <p>{`The second option is to create a custom certification. If you create a custom certification you will need to give it a name.`}</p>
    <p>{`In this example we will create a custom certification called "White Card".`}</p>

    <Screen4 mdxType="Screen4" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Canyou tracks certifications and licence expiry dates so that the system admins will receive an email notifying before they expire.`}</li>
    </ol>
    <p>{`In this step we need to configure the number of days to be notified before a certification expires. In this example we configure a notification 90 days before certification expiry.`}</p>

    <Screen5 mdxType="Screen5" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click the "Create" button.`}</li>
    </ol>

    <Screen6 mdxType="Screen6" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`We can now see that the certification has been configured.`}</li>
    </ol>

    <Screen7 mdxType="Screen7" />
    <p>{`The next step is assigning the certification requirements to our Positions or to individual employees.`}</p>

    <SupportFooter linkTo="/support/certifications/assigning-certifications-to-positions" linkText="Assign to a Position" mdxType="SupportFooter" />
    <SupportFooter linkTo="/support/certifications/assigning-certifications-to-an-employee" linkText="Assign to an Employee" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      